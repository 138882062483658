.scan-qr {
  width: 100%;
  height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .camera-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // .take-picture-btn {
  //   position: absolute;
  //   bottom: 80px;
  //   background-image: none;
  //   background-color: var(--theme-green);
  //   border: none;
  //   border-radius: 5px;
  //   box-shadow: none;
  //   color: white;
  //   width: 90%;
  //   max-width: 400px;
  //   padding: 20px;
  //   text-align: center;

  //   span {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 10px;
  //   }
  // }
}
