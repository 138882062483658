.registration-step {
  display: flex;
  flex-direction: column;
  margin: 10% 10% 0% 10%;
}

.registration-step .bp3-button {
  border-radius: 1rem;
  padding: 1% 5%;
  background-color: var(--theme-green);
  box-shadow: none;
  color: white;
  background-image: none;
  margin: 5% auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
}

.registration-step .footer {
  width: 100%;
  margin: 2% auto;
}

.registration-step .footer button{
  border-radius: 5px;
  padding: 10px;
}

.hp-number, .otp-number, .password{
  margin: 3em 0 2em;
}

.registration-step .header{
  padding: 0;
}

.hp-field select{
  border: 1px solid #ddd;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.hp-field input{
  line-height: unset;
  border: 1px solid #ddd;
  border-left: 0;
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  outline: none;
}

.otp-field input{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  outline: none;
}

.password-field input{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1em;
}