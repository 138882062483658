@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.clock-in {
  width: 100%;
  position: relative;
  margin-bottom: 85px;

  .container {
    padding: 10px 20px;
  }
  
  .reason {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-light);
    font-family: Poppins;
    margin-bottom: 50px;
    &::-webkit-input-placeholder {
      font-family: Poppins;
    }
  }

  .submit-btn {
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    color: white;
    width: 100%;
    padding: 20px;
    text-align: center;
  }
}
