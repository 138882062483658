.profile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.profile-header {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
}

.profile-card {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
}

.profile-card .profile-card-header {
    font-size: 1.5rem;
    font-weight: bold;
}

.profile-card .profile-card-body {
    margin: 2rem 1rem 1rem;
}

.profile-card .profile-card-button {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  font-family: Poppins;
}

.profile-card .profile-card-button:last-of-type {
    width: 100%;
    margin: 0;
}

.profile-card .profile-hr {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.profile-footer {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
}

.profile-card .profile-text {
    font-size: 1.6rem;
}

.carding{
    width: 100%;
    margin-bottom: 1em;
    background-color: #fff;
}

.profile-pict{
   width: 70px;
   height: 70px;
   margin:2em auto;
   
   img{
       width: 100%;
       border-radius: 50%;
   }
}
.new-profile-container{
    width: 100%;

    .profile-box{
        padding: 3em 2em;
        

        .profile-info{
            text-align: center;
            padding: 0 4em;
            p{
                margin-top: 1em;
                font-weight: bold;
            }
        }

        .edit-profile{
            text-align: right;
            a{
                color: #3bc6c8;
            }
        }
    }
}

.text-success{
    color: #3bc6c8;
}

.d-flex{
    display: flex;
}

.ml-auto{
    margin-left: auto;
}

.data-box{
    padding: 1em 2em;
    ul{
        padding-left: 0;
        li{
            &:first-child{
                margin-bottom: 1.5em;
            }
            list-style-type: none;
        }
    }
}

.spesific-box{
    padding: 1em 2em;
}

.bp3-card{
    box-shadow: 0px 0px 5px 2px #ddd;
    border-radius: 5px;
}

.profile-card-body button{
    box-shadow: unset !important;
    border: 0 !important;
    background-color: #18b8ba !important;
    background-image: unset !important;
    color: #fff !important;
}

.bp3-intent-danger{
    background-color: #f31a5a !important;
}