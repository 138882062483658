.contact-container{
  padding: 3rem;
  width: 100%;

  .form-container{
    .form-group{
      margin: 2em 0;

      label{
        color: #6a6a6a;
      }
      input, textarea{
          padding: 10px 10px 10px 0;
          width: 100%;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 1px solid #ddd;
          background-color: transparent;

          &:focus{
            outline: none;
          }
      }
    }
  }
}

.button-box{
  margin-top: 5em;
}

.btn {
  width: 100%;
  border: 0;
  padding: 1em;
  border-radius: 5px;
  color: #fff;

  &.btn-success{
    background-color: #57b7ba;
  }
}