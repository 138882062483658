.request-page-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.request-page-container .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5% auto;
  text-align: center;
}

.request-page-container .hr {
  background-image: none;
  margin: 2% auto;
  width: 80vw;
  display: none;
}

hr {
  border-style: solid;
  color: var(--theme-light-gray);
}

.request-page-container .label {
  text-transform: capitalize;
}

.request-page-container .amount {
  font-size: 2.2rem;
  color: var(--theme-purple);
}

.value{
  font-weight: bold;
  color: #000;
}

.request-page-total-allowance .amount {
  flex-direction: row;
  display: flex;
  margin: 2% auto;
}

.request-page-total-allowance .amount .icon {
  color: var(--theme-gray);
}

.request-page-total-allowance .amount .icon {
  margin: auto;
}

.request-page-withdrawal-amount .amount {
  flex-direction: row;
  display: flex;
  margin: 0 auto;
}

.request-page-withdrawal-amount .bp3-slider {
  width: 100%;
  height: 10vw;
}

.request-page-withdrawal-amount .bp3-slider-label {
  display: none;
}
.request-page-withdrawal-amount .input-field-amount {
  display: "flex";
  flex-direction: "row";
  justify-content: "center";
  margin: 2em 0;
}

.bp3-slider-progress.bp3-intent-primary{
  background-color: #52b5b8 !important;
}

.request-page-withdrawal-amount .input-field-amount input{
  padding: 2%;
  opacity: 1;
  line-height: 1.5;
  font-family: Poppins;
  font-size: 1.2rem;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 3px;
}

.request-page-withdrawal-amount .input-field-amount input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
    opacity: 1;
    width: 25px;
}

.request-page-container .container.submit-button {
  margin-bottom: 0% auto 20% auto;

}

.request-page-container .submit-button .bp3-button {
  font-size: 1.2rem;
  color: white;
  background-color: var(--theme-green);
  background-image: none;
  box-shadow: none;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
}

.request-page-container .submit-button .bp3-button:disabled {
  background-color: var(--theme-medium-gray);
}

.container.youtube .bp3-button {
  background: none;
  color: var(--theme-green);
}

.banner {
  width: auto ;
  max-width: 100% ;
  height: auto ;
}

.bp3-dialog {
  background-color: white;
  height: 70vh;
  display: flex;
  flex-direction: column;
  margin: 4%;
  padding: 4%;
}

.request-page-confirmation-container {
  font-size: 16px;
  color: var(--theme-gray);
  margin-top: 2em;
  font-weight: bold;
  position: relative;
}

.request-page-confirmation-container.body .dialog-hr {
  margin-top: 5%;
  margin-bottom: 5%;
}

.confirmation-amount-group {
  margin-bottom: 15px;
}

.justify-between {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.confirmation-amount {
  text-align: center;
  font-size: 1.8rem;
}

.subtitle {
  font-size: 1.2rem;
}

.confirmation-fee {
  color: var(--theme-red);
}

.confirmation-principal {
  color: var(--theme-green);
}

.request-page-confirmation-container.message-input textarea {
  min-height: 100px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  padding: 10px;
}

.request-page-confirmation-container input {
  border: var(--theme-green) solid 0.01em;
  border-radius: 0.3em;
  width: 100%;
  padding: 2%;
  background-color: rgba(82, 181, 184, 0.07);
  margin: 2% 0%;
}

.request-page-confirmation-container.footer {
  text-align: right;
}

.request-page-confirmation-container.footer .bp3-button {
  border-radius: 2em;
  box-shadow: none;
  background-image: none;
  font-family: Poppins;
}
.request-page-confirmation-container.footer .bp3-button.cancel {
  background-color: white;
  color: var(--theme-medium-gray);
}

.request-page-confirmation-container.footer .bp3-button.confirm {
  background-color: var(--theme-green);
  color: white;
}

.request-page-confirmation-container.footer .bp3-button.disabled {
  background-color: var(--theme-medium-gray);
  color: white;
}


.request-card{
  padding: 2em;
  background-color: #fff;
  margin: 2em;
  box-shadow: 1px 1px 10px 1px #ddd;
  border-radius: 6px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bp3-slider-handle{
  border-radius: 50%;
}

.bp3-slider-progress{
  border: 1px solid #52b5b8;
  background: #52b5b840 !important;
}

.bp3-portal{
  z-index: 999;
}

.dialog-request{
  width: 100%;
  height: 100% !important;
  position: absolute;
  overflow-y: scroll;
  z-index: 99999;
}

.d-flex{
  display: flex;
}

.arrow-back{
  position: absolute;
  bottom: -3px;
}

.arrow-back button{
  border: 0 !important;
  box-shadow: unset !important;
  background-color: unset !important;
}

.confirmation-title{
  text-align: center;
}

.step-wrap{
  background-color: red;
  width: 12rem;
  display: flex;
  position: relative;
  margin: auto;
  padding: 0 5px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.step-first{
  width: 30px;
  height: 30px;
  background-color: #52b5b8;
  border-radius: 50%;
  position: absolute;
  left: 0;
  z-index: 9;
  padding: 2px;
}

.step-distance{
  width: 90%;
  height: 7px;
  position: absolute;
  top: 12px;
  left: 6px;
  border: 1px solid #52b5b8;
  background-image: -webkit-linear-gradient(left, #53b5b8, #52b5b8 50%, white 30%, white);
}

.step-other{
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  padding: 5px;
  text-align: center;
  color: #000;
  font-size: 12px;
  border: 2px solid #52b5b8;
}

.step-title{
  position: absolute;
  color: #000;
  width: 100px;
  left: -30px;
  bottom: -25px;
}

.step-wizard{
  margin: 2rem 0 6rem;
  width: 100%;
}

.reason-wrap input[type="radio"]:checked+label {
  border: 2px solid #52b5b8;
}

.reason-wrap input[type="radio"] {
  display: none;
}

.reason-wrap label {
  display: inline-block;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #b9b9b9;
  margin: 5px 0;
}

.confirm-btn{
  width: 100%;
  border-radius: 5px !important;
  padding: 12px;
}

.success-img{
  margin: 3em 0 2em;
}

.success-img img{
  width: 75px;
}

.success-content{
  text-align: center;
  padding-top: 5rem;
}

.footer-content{
  margin-top: 5em;
}

.footer-content button {
  width: 100%;
  margin-top: 1em;
  padding: 15px;
  background-color: #18b8ba;
  color: #fff;
  border: 0;
  border-radius: 5px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #52b5b8;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #52b5b8;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--theme-gray);
  cursor: pointer;
}