.container.language-switch {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  justify-content: flex-start;
  font-style: italic;
}

.container.language-switch .choices {
  margin: auto 0%;
  display: flex;
}

.container.language-switch button {
  padding: 2%;
  font-family: Poppins;
  border: transparent;
  border-radius: 1em;
  background-color: var(--theme-light-gray);
  color: white;
  margin: auto 1%;
  width: 20%;
}

.container.language-switch .bahasa {
  background-color: var(--theme-purple);
}

.container.language-switch .english {
  background-color: var(--theme-pink);
}

.container.tnc {
  display: flex;
  /* flex-direction: column; */
  height: 45vh;
  font-size: 0.7em;
  text-align: justify;
  margin: 0% 0% 10% 0%;
}

.container.tnc .language {
  height: 100%;
  display: flex;
  overflow-y: scroll;
  padding: 3%;
}

.company-tag {
  text-align: right;
  font-size: 1.5rem;
  color: var(--theme-medium-gray);
  font-family: Poppins;
}

.header {
  color: var(--theme-green);
  font-size: 1.5rem;
  padding: 2%;
}

.hp {
  font-size: 1em;
  padding: 2%;
  background-color: white;
}

.hp .container.tnc {
  display: flex;
}

.hp .container.tnc img {
  margin: 0;
  width: 100%;
}

.hp .container.hp-number .hp-field {
  display: flex;
  flex-direction: row;
  margin: 2% 0;
}
.hp .container.otp-number .otp-field {
  display: flex;
  flex-direction: row;
  margin: 2% 0;
}

.hp .container.password .password-field {
  display: flex;
  flex-direction: column;
  margin: 2% 0;
}

.hp .container.biz-code input {
  width: 100%;
  padding: 2%;
}

.hp .container.hp-number .hp-field input {
  box-shadow: none;
  padding: 3%;
  width: 100%;
  font-family: Poppins;
  font-size: 1em;
}

.hp .container.otp-number .otp-field input {
  box-shadow: none;
  padding: 3%;
  width: 100%;
  font-family: Poppins;
  font-size: 1em;
}
.hp .container.password .password-field input {
  box-shadow: none;
  padding: 3%;
  width: 100%;
  font-family: Poppins;
  font-size: 1em;
  margin-bottom: 2%;
}

.hp .container .hp-field select {
  display: flex;
  width: 30%;
  font-family: Poppins;
  font-size: 1em;
}

.hp .container.tnc .file {
  height: 40vh;
  border: solid 1px black;
  margin-bottom: 5%;
}

.hp .agreement {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ktp {
  font-size: 1rem;
  padding: 2%;
  background-color: white;
}

.ktp .body {
  display: flex;
  flex-direction: column;
  padding: 2%;
  margin: 2%;
}

.ktp .body input {
  padding: 2%;
  font-family: Poppins;
  font-size: 1em;
}

.ktp .body .fixed-data {
  padding: 2%;
  font-size: 1.5em;
}

.bank {
  font-size: 1em;
  padding: 2%;
  background-color: white;
}

.bank .body {
  padding: 2%;
}

.bank .body select {
  width: 35%;
  padding: 1.5%;
  font-size: 1em;
  font-family: Poppins;
}

.bank .body input {
  width: 65%;
  padding: 2%;
}
