.request-page-confirmation-container{
  box-shadow: 0px 3px 4px #ddd;
  padding-bottom: 10px;
}

.arrow-back{
  bottom: 8px;
}

.flex{
  display: flex;
}

.title-slip{
  p{
    font-weight: bold;
  }
  
  small{
    margin-left: auto;
  }
}

.ml-auto{
  margin-left: auto;
}

.mt-2{
  margin-top: 1em;
}

.mt-4{
  margin-top: 2em;
}

.pl-1{
  padding-left: 1em !important;
}

.dot{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 15px;
  position: absolute;

  &.success{
    background-color: #18b8ba;
  }
}

.payslip-page{
  padding-bottom: 5em;
  .card-body{
    padding-left: 3rem;
    position: relative;
  }
}

.badge{
  padding: 6px;
  border-radius: 16px;
  color: #fff;

  &.badge-success{
    background-color: #18b8ba;
  }
}

small.line{
  letter-spacing: 5px;
}

a{
  color: unset;
}

.payslip-detail-page{
  padding-bottom: 15em;
}

.d-none{
  display: none;
}

.text-center{
  text-align: center;
}

.btn-danger{
  background-color: #ff7b91 !important;
}