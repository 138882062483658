.history-detail-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.history-detail-popup .dialog-hr {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
}

.history-detail-popup .history-detail-header {
  font-size: 2.5rem;
}

.history-detail-popup .history-detail-body {
}

.history-detail-popup .history-detail-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.history-detail-popup .history-detail-footer-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.history-detail-popup .history-detail-subtitle {
  margin-top: 5px;
  opacity: 0.55;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.history-detail-popup .history-detail-description {
  font-size: 1.3rem;
  padding-top: 10px;
}

.history-detail-popup .history-detail-status {
  margin-top: 5px;
  font-size: 1.5rem;
}

.history-detail-popup .history-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5%;
  padding-top: 5%;
}

.history-detail-popup .history-detail-row .history-detail-type {
  display: flex;
  font-size: 1.7rem;
  margin-top: auto;
  margin-bottom: auto;
}

.history-detail-popup .history-detail-row .history-detail-value {
  display: flex;
  font-size: 2rem;
}
