.update-password-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.update-password-dialog .update-password-header .update-password-title {
    font-size: 2.5rem;
}

.update-password-dialog .update-password-footer-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.update-password-dialog .update-password-footer-details .bp3-button {
  font-family: Poppins;
}
