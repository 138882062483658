.history-page {
  margin-top: 4px;
  width: 100%;

  .tabs {
    width: 100%;
    background-color: #fff !important;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 999;

    .tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      cursor: pointer;

      &.active {
        border-bottom: 2px var(--theme-medium-gray) solid;
      }
    }
  }

  .limit {
    margin-top: 50px;
  }

  .filter {
    padding: 10px 20px;
    background-color: rgb(236, 236, 236);
    z-index: 0;
    .date-range-input {
      margin: auto;
      .bp3-control-group {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      .bp3-input-group {
        flex-shrink: unset;
        width: 40vw;
        z-index: 0;
      }
    }
  }

  .no-data {
    width: 100%;
    height: 77vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .history-list {
    width: 100%;
    height: 77vh;
    padding: 10px;
    margin-bottom: 10px;
    overflow: auto;
    
    .history-item {
      width: 100%;
      padding: 20px 15px 10px 15px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #fff;
      border-radius: 8px;

      .spaced-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
      }

      .detail-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: var(--theme-medium-gray) 1px solid;
        padding-top: 5px;
        color: var(--theme-medium-gray);
      }

      .label {
        padding: 7px 15px;
        border-radius: 20px;
        font-size: 12px;
        color: #fff;

        &.success {
          background-color: var(--theme-medium-gray);
        }

        &.warning {
          background-color: #fff;
          color: rgb(207, 207, 119);
          border: rgb(207, 207, 119) 1px solid;
        }
      }

      .text {
        font-size: 12px;
        color: var(--theme-medium-gray);
      }

      .bold-text {
        color: black;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}