.map-page {
  .leaflet-container {
    width: 100%;
    height: 300px;
  }

  .form-container {
    width: 100%;
    padding: 20px;

    textarea {
      width: 100%;
      height: 100px;
      resize: none;
      padding: 10px;
      border: none;
      border-radius: 3px;
      background-color: var(--theme-light);
      font-family: Poppins;
      &::-webkit-input-placeholder {
        font-family: FontAwesome, Poppins;
      }
    }

    .btn {
      margin-top: 20px;
      background-image: none;
      background-color: var(--theme-green);
      border: none;
      border-radius: 5px;
      box-shadow: none;
      color: white;
      width: 100%;
      padding: 20px;
      text-align: center;
    }
  }
}
