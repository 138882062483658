.outstation {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;

  input {
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-light);
    font-family: Poppins;
  }

  select {
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-light);
    font-family: Poppins;
  }

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-light);
    font-family: Poppins;
    &::-webkit-input-placeholder {
      font-family: Poppins;
    }
  }

  .submit-btn {
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    color: white;
    width: 100%;
    padding: 20px;
    margin-top: 85px;
    text-align: center;
  }
}
