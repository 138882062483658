.history-page {
  width: 100vw;
}

.history-page .balance {
  display: flex;
  flex-direction: row;
  padding: 2% 10%;
  font-size: 1.3rem;
}

.history-page .balance .key {
  margin: auto auto auto 0%;
  display: flex;
}
.history-page .balance .value {
  display: flex;
  margin-left: auto;
  font-size: 1.8rem;
}

.history-page .item1 {
  color: var(--theme-medium-gray);
}

.history-page .item2 {
  color: var(--theme-red);
}

.container.logs {
  font-size: 1.3rem;
}

.container.logs .content {
  display: flex;
  flex-direction: row;
}
.container.logs .content .details-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.container.logs .content .details-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
.container.logs .content .details-right .tx-id {
  color: var(--theme-medium-gray);
  font-size: 1.2rem;
}

.container.logs .content .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.container.logs .content .status {
  margin-left: auto;
  font-size: 1.2rem;
}

.warning {
  color: orange;
}

.success {
  color: var(--theme-green);
}

.danger {
  color: var(--theme-red);
}

.container.logs .timestamp {
  color: var(--theme-medium-gray);
  font-size: 1.2rem;
}

.bp3-dialog {
  background-color: white;
  height: 70vh;
  display: flex;
  flex-direction: column;
  margin: 4%;
  padding: 4%;
}

.date-range-input .bp3-input-group {
  flex-shrink: unset;
  width: 40vw;
}
