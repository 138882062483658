:root {
  --theme-green: rgb(82 181 184);
  --theme-light-green: rgb(213 237 211);
  --theme-red: rgb(235, 65, 106);
  --theme-pink: rgb(255, 123, 145);
  --theme-purple: rgb(133, 150, 220);
  --theme-gray: rgb(47 55 71);
  --theme-medium-gray: rgb(178 188 202);
  --theme-light-gray: rgb(238 242 246);
  --theme-plum: rgb(72 29 60);
  background-color: var(--theme-light-gray);
  color: var(--theme-gray);

  --theme-light: #ebeef5;
  --theme-border: #e8e8e8;
  --theme-dark-gray: rgb(178 188 202);
  --theme-font-upload: #a7a7ad;
  --theme-font-header: #205072;
  --theme-font-legend: #464646;
  --theme-font-value: #222222;
  --theme-font-button: #505050;
  --theme-font-name: #747474;
  --theme-font-button-light: #fff;
  --theme-font-popup-title: #4a4a4a;
  --theme-font-popup-subtitle: #6b6b6e;
}


html {
  font-size: 12px;
  line-height: 1.5;
  font-family: Poppins;
}

body {
  font-family: Poppins;
}

@media only screen and (min-width: 425px) {
  html {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1024px) {
  html {
    font-size: 20px;
    line-height: 1.5;
  }
}
