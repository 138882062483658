body{
  scroll-behavior: smooth;
}

.homepage-wrapper{
    width: 100%;
    padding: 0px 2em;
    z-index: 9;
    padding-bottom: 10em;
    .carding-wrap{
        width: 100%;
        height: 100vh;

        a{
            text-decoration: none;
            strong{
                color: #000;
            }
        }
    }

    .user{
        display: flex;
        margin: 2em 0;

        .user-pict{
            width: 40px;
            height: 40px;
            margin-right: auto;

            img{
                width: 100%;
                border-radius: 50%;
            }
        }

        .bell-icon{
            margin-top: .5em;
        }
    }
}

.name{
    margin: 3em 0;

    h2{
        color: #fff;
    }
}

.jumbotron{
    width: 100%;
    height: 23rem;
    background: #18b8ba;
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.card{
    background-color: #fff;
    box-shadow: 0 2px 6px 1px #ddd;
    border-radius: 12px;
    margin: 1em 0;

    .card-body{
        padding: 2em 1em;

        h3{
            margin-bottom: 1em;
        }

        .desc{
            padding-right: 35px;
        }

        .icon-card{
            padding-top: 15px;
        }
    }

}

.d-flex{
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.text-secondary{
    color: #959595;
}

h3{
    margin: 0;
}

.home{
    top: unset !important;
    height: 100% !important;
    position: relative !important;
    padding-bottom: 5rem;
    padding-top: 0 !important;
}

.bp3-icon-notifications{
    color: #fff;
}

.forbidden-box{
    text-align: center;

    svg{
        color: #fd4c4c;
        width: 50px;
        height: 50px;
        margin-top: 2em;
    }

    p{
        margin-top: 3em;
    }

    button{
        width: 100%;
        border: 0;
        padding: 12px;
        background-color: #58b7ba;
        color: #fff;
        margin-top: 5em;
        border-radius: 5px;
    }
}