.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  background: white;
}

.dashboard-container .dashboard-header {
  background: white;
  position: fixed;
  top: 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 2em 0 1em;
  z-index: 999;
  box-shadow: 0 0px 6px 6px #ddd;
}

.dashboard-container .dashboard-header .dashboard-title {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  font-weight: bold;
  flex-grow: 4;
}

.dashboard-container .dashboard-header .dashboard-reload {
  display: block;
  position: fixed;
  right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 1;
  /* display: none; */
}

.dashboard-container .dashboard-body {
  background: rgb(247, 250, 252);
  top: unset;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: scroll;
  padding: 5rem 0;
}

.dashboard-container .dashboard-footer {
  background: white;
  position: fixed;
  bottom: 0;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  z-index: 999;
}

.dashboard-container .dashboard-footer .link {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 10px;
}

.dashboard-container .dashboard-footer .link svg {
  font-size: 20px;
}

.dashboard-container .dashboard-footer .link.link-selected {
  background-color: var(--theme-light-gray);
}

.dashboard-container .dashboard-footer .link.link-selected svg{
  color: #18b8ba;
}

.dashboard-container .dashboard-footer .link > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-container .dashboard-footer .link .nav-icon > span > svg {
  width: 4em;
}

.footer-container-icon {
  margin: 0 auto;
  text-align: center;
}

iframe#launcher{
  bottom: 50px !important;
}