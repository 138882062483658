.shift-request-page{
  padding: 3rem 2em 10rem;

  h3{
    margin-bottom: .5em;
  }

  .text-info{
      color: #52b5b8;
  }

  .text-secondary{
      color: #a9a9a9;
  }

  .row-custom{
      display: flex;
      justify-content: space-between;
  }

  .current-shift{
    text-align: center;
  }

  .list-custom{
    padding-left: 0;
    list-style: none;

    li{
        border-bottom: 1px solid #ddd;

        &:not(:first-child){
          padding-top: 20px;
        }
    }
}

}
label{
  margin-bottom: 1em;
}

input, .form-control{
  border: 1px solid #ddd !important;
  margin-top: 1em;
  padding: 10px;
}

.btn-info{
  background-color: #52b5b8;
  color: #fff;
}

.w-100{
  width: 100%;
}