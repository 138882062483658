.home {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 15px;

    img {
      width: 70px;
      height: 70px;
      background-color: var(--theme-dark-gray);
      border-radius: 100%;
      object-fit: cover;
    }

    .greeting {
      font-size: 20px;
      font-weight: 700;
      color: var(--theme-font-value);
    }

    .name {
      margin: 0px !important;
      color: var(--theme-font-name);
      text-transform: capitalize;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    .btn {
      border-radius: 3px;
      background-image: none;
      background-color: var(--theme-green);
      border: none;
      color: white;
      width: 100%;
      box-shadow: hsl(0, 0%, 40%) 0px 0px 3px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .text-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .title {
          font-size: 18px;
          font-weight: 700px;
        }

        .desc {
          font-size: 10px;
          font-weight: 300;
        }
      }
    }
  }

  .upcoming-trip {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .card {
      width: 100%;
      border-radius: 10px;
      box-shadow: hsl(0, 0%, 40%) 11px 11px 42px -14px;
      background-color: #fff;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .trip-detail {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 20px;

        .time {
          text-align: center;
          .top {
            font-size: 28px;
            color: #4e4e4e;
          }
          .bottom {
            font-size: 10px;
            color: #b1b1b1;
          }
        }

        .detail {
          margin: 0;
          text-align: center;
          .desc {
            font-size: 10px;
            color: #838383;
          }
          .time {
            font-size: 18px;
            color: #4e4e4e;
            font-weight: 900;
            margin: 5px 0;
          }
          .from-to {
            font-size: 12px;
            color: #b1b1b1;
          }
        }
      }

      .button {
        width: 100%;
        background-color: var(--theme-green);
        outline: none;
        border: none;
        border-radius: 3px;
        color: #fff;
        font-size: 16px;
        height: 40px;
      }
    }
  }
}
