.registration-step {
  display: flex;
  flex-direction: column;
  margin: 10% 10% 0% 10%;
}

.registration-step .bp3-button {
  border-radius: 1rem;
  padding: 1% 5%;
  background-color: var(--theme-green);
  box-shadow: none;
  color: white;
  background-image: none;
  margin: 5% auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
}

.registration-step .footer {
  width: 50%;
  margin: 2% auto;
}

.ktp .body{
  margin: 1em 0;
  padding: 0 !important;
}