.ktp-upload-popup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.ktp-upload-popup .dialog-hr {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
}

.ktp-upload-popup .ktp-upload-detail-header .ktp-upload-detail-title {
    font-size: 2.5rem;
}

.ktp-upload-popup .ktp-upload-detail-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
}

.ktp-upload-popup .ktp-upload-detail-image {
    overflow-y: scroll;
    width: 100%
}

.ktp-upload-popup .ktp-upload-detail-body img {
    width: 100%;
}

.ktp-upload-popup .ktp-upload-detail-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ktp-upload-popup .ktp-upload-detail-footer-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ktp-upload-popup .ktp-upload-detail-footer-details .bp3-button {
  font-family: Poppins;
}
